<template>
  <div class="">
    <div v-show="planListData.length === 0">
      <p class="mt-5 text-center font-weight-bold">尚未加入行程</p>
    </div>
    <div>1234</div>
    <MyPlanList
        :plan-type="planType"
        :plan-list-data="newListData"
    ></MyPlanList>
  </div>
</template>

<script>
import MyPlanList from "@/views/plan/MyPlanList";

export default {
  name: 'MyPlanJoined',
  data() {
    return {
      
    };
  },
  props: {
    planType: {
      required: true,
    },
    planListData: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
  },
  components: {
    MyPlanList,
  },
  computed: {
    newListData() {
      let newData = []
      if(this.planListData.length) {
        this.planListData.map(function(item) {
          let newObj = {}
          newObj.name = item.name;
          newObj.picUrl = item.picUrl;
          newObj.path = '/journey/' + item._id;
          newObj.startDate = item.startDate;
          newObj.endDate = item.endDate;
          newData.push(newObj);
        });
      }
      return newData
    },
	},
  watch: {
    
  },
  mounted() {
    
	},
  methods: {
    
  }
}
</script>


<style scoped>
  
</style>
